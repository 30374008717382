import { Injectable } from '@angular/core';
import { ToastController } from "@ionic/angular";
import { Router } from '@angular/router';
import * as mime  from 'mime';
import { BastaniLoadingComponent } from 'src/app/components/bastani-loading/bastani-loading.component';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
})
export class CoreService {

    constructor(
        public router: Router,
        public toastController: ToastController,
        public loadingService : LoadingService
    ) { }

    public isLoading:boolean = false;

    async presentLoading(message : string = 'Aguarde...', duration: number = 0) {
        this.loadingService.show(message);
        if(duration <= 0) return;  
        await new Promise(resolve => setTimeout(resolve, duration * 1000));
        this.loadingService.hide();
    } 

    textToBinary (str = '') {
        let res = '';
        res = str.split('').map(char => {
           return char.charCodeAt(0).toString(2);
        }).join(' ');
        return res;
    };

    escapeHtml(text:string) {
        return text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    hideLoading() {
        this.loadingService.hide();
    }

    async message(
        message: any,
        type = "primary",
        position = "bottom",
        time = 5000,
        buttons: any = [
            {
                text: "Fechar",
                role: "cancel",
                handler: () => { },
            },
        ],
        navigate = ''
    ) {
        const toast = await this.toastController.create({
            message: message,
            color: type,
            position: position as "bottom",
            duration: time,
            mode: 'ios',
            buttons: buttons,
        });
        toast.dismiss();
        toast.present();
        if (navigate) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.router.navigate(['/' + navigate], { replaceUrl: true});
        }
    }

    backDash() {
        this.router.navigate(['/']);
    }

    navigate(router:string) {
        this.router.navigate(['/'+router]);
    }

    validateEmail(email: any) {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    showProfile(value: any) {
        this.router.navigate(['/pages/profile', { user_id: typeof value.user_id != "undefined" ? value.user_id: '', doc: value.doc}]);
    }

    openLink(link:any) {
        window.open(link, "_blank");
    }

    copyToTransfer(value:string) {
        let selBox  = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.message('Copiado para a área de transferência!', 'success', 'top');
    }

    warningDevelopment(){
        this.message('Isto está em desenvolvimento e em breve estará disponível.', 'warning', 'top');
    }

    openWpp(phone:string, text:string) {
        window.open('https://api.whatsapp.com/send?phone=55' + phone + '&text=' + text.replace('%', ' '), "_blank");
    }
    
    getMimeType(ext:string) {
        mime.getType(ext);
    }

    /**
     * Navega abrindo nova haba
     * @param route - rota - ex '/home'
     * @param params - parametros da rota ex {id: 1}
     */
    navigateToAnoterTab(route: string, params: {} = {}) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([route, params])
        );
        window.open('#'+url, '_blank');
    }

    /**
     * Transforma um objeto em uma string de query params
     * @param object 
     */
    static generateQueryParamsWithObject(object:{}){
        let string = '';
        for (const [key, value] of Object.entries(object)) {
            if(!value) continue;
            string += (!string?'?':'&') + key + '=' + value;
        }
        return string;
    }

    /**
     * Retorna o header padrao
     * @param contentType 
     * @param accept 
     * @return object
     */
    static getDefaultHeader(contentType : string = 'application/json', accept : string = 'application/json') {
        return {
            'Content-Type': contentType,
            Accept: accept,
            api: 'true',
        };
    }

}
