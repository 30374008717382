<div class="image-container" [ngClass]="imageLoaded ? 'd-none' : ''">
    
    <div class="scroll-wrapper">
        <div class="image">
            <pdf-viewer class="pdf" alt="Erro ao carregar PDF" [src]="pdf" [render-text]="true" [original-size]="false" [zoom]="zoom"
                [rotation]="rotation">
            </pdf-viewer>
        </div>
    </div>
    <div class="panel">
        <button class="fab btn-ajuste" color="warn" *ngIf="positionChanged" mat-fab (click)="resetImagePosition()">
            <mat-icon>center_focus_strong</mat-icon>
        </button>
        <button class="fab" color="primary" mat-fab (click)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
        </button>
        <button class="fab" color="primary" mat-fab (click)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
        </button>
        <button class="fab" (click)="rotateLeft()" color="primary" mat-fab>
            <mat-icon>rotate_left</mat-icon>
        </button>
        <button class="fab" color="primary" mat-fab (click)="rotateRight()">
            <mat-icon>rotate_right</mat-icon>
        </button>
        <button class="fab" color="primary" mat-fab (click)="openFullScreenMode()">
            <mat-icon>fullscreen</mat-icon>
        </button>
    </div>
    <div class="col zoom-div">
        <div class="{{showZoom ? 'show' : 'hide'}}">{{(zoom*100).toFixed()}}%</div>
    </div>
</div>